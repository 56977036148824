import React from "react";

function Overview({ aws, isMobileView, locale }) {
  return (
    <>
      {
        aws?.data?.map((overview, index)=>(
          <div className="partnerHotspot" id="overview">
          <div className={`partner ${index!==0 ? 'pt-0': ''}`}>
            <div className="global-margin partner-container">
              <div className={` ${isMobileView ? "" : "d-flex"}`}>
                <div className="partnerLeftSection flex-1">
                  <div
                    className={` ${
                      isMobileView
                        ? "partnerTitle font-weight-700 font-size-24 text-center light-blue-color "
                        : "partnerTitle font-weight-700 font-size-24 light-blue-color "
                    }`}
                  >
                    {overview?.title}
                  </div>
                  <div
                    className={` ${
                      isMobileView
                        ? `partnerHeading roboto font-weight-500 text-center line-height-45 font-size-40 dark-blue-color my-4 ${
                            locale !== "sv" && "capitalize"
                          }`
                        : `partnerHeading roboto font-weight-500 line-height-45 font-size-40 dark-blue-color my-4 ${
                            locale !== "sv" && "capitalize"
                          }`
                    }`}
                  >
                    {overview?.heading}
                  </div>
                  <div
                    className={` ${
                      isMobileView
                        ? `partnerDescription  font-weight-400 font-size-20  text-center line-height-35 ${
                            locale !== "sv" && "capitalize"
                          }`
                        : ` font-weight-400 font-size-20 line-height-35 ${
                            locale !== "sv" && "capitalize"
                          }`
                    }`}
                  >
                    {overview?.description}
                  </div>
                </div>
                <div
                  className={` ${
                    isMobileView
                      ? "d-flex justify-content-center"
                      : "partnerRightSection"
                  } flex-1`}
                >
                  <div className="aws_par_wrap pt-5 d-flex justify-content-end">
                        {
                          index === 0 &&
                          <>
                            <div className="aws_inside p-2">
                              <img className="aws-img " src={aws.logo[0]?.url} alt="consultPartner" />
                            </div>
                            <div className="aws_inside p-2">
                              <img className="aws-img " src={aws.logo[1]?.url} alt="consultPartner" />
                            </div>
                          </>
                        }

                        {
                          index === 1 &&
                          <>
                            <div className="aws_inside p-2">
                              <img className="aws-img " src={aws.logo[2]?.url} alt="consultPartner" />
                            </div>
                            <div className="aws_inside p-2">
                              <img className="aws-img " src={aws.logo[3]?.url} alt="consultPartner" />
                            </div>
                          </>
                        }
                        {
                          index === 2 &&
                          <>
                            <div className="aws_inside p-2">
                              <img className="aws-img " src={aws.logo[4]?.url} alt="consultPartner" />
                            </div>
                          </>
                        }

                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        ))
      }
    </>
  );
}

export default Overview;
